import PropTypes from 'prop-types'
import React, {createContext, useContext, useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import useEventListener from 'Src/utilities/useEventListener'
import {WINDOW_IFRAME_OPEN_MESSAGE} from 'Src/utilities/useSlideOutState'
import {getLeadTradeAndDealData} from '../actions/RegistrationActions'
import {LOADING_STATE} from '../reducers/NavReducer'
import {useAxios} from '../utilities/useAxios'
import {useToken, TOKEN_STATE} from '../utilities/useToken'
import {useCustomerDispatchContext, useCustomerStateContext} from './CustomerContext'
import {useDealDispatchContext, useDealStateContext} from './DealContext'
import {useNavDispatchContext} from './NavContext'
import {whoami} from '../apis/VerificationActions'
import {useRegistrationContext} from './RegistrationContext'
import {retrieveDealData} from '../actions/DealActions'
import {getInitialState as getInitialDealState} from '../reducers/DealReducer'
import useConfig from '../utilities/useConfig'
import {useAnalytics} from '../services/analytics/useAnalytics'
import {initalizeShiftAnalytics} from '../services/analytics/initializeShiftAnalytics'
import {trans} from '../utilities/Helpers'
import Error from '../components/pages/Error'
import {useCommonDependencies} from '../utilities/useCommonDependencies'
import {useSessionId} from '../utilities/useSessionId'
import {ACTION_DETAILS} from '../services/analytics/constants'

export const FrameContext = createContext({
  a2zApi: undefined,
  dealership: {id: undefined, attributes: {phone: undefined}},
  messages: [],
  setMessages: () => undefined,
})

/**
 * @returns {
 *    {
 *      a2zApi: undefined,
 *      dealership: {
 *        id: undefined,
 *        attributes: {
 *          phone: undifined,
 *          name: undefined
 *        }
 *      },
 *      messages: [],
 *      setMessages: () => undefined,
 *      isIframeVisible: false,
 *      isShareQuoteEnabled: null,
 *      isSaveDealEnabled: null
 *    }
 *  }
 */
export const useFrameContext = () => useContext(FrameContext)

export const FrameContextProvider = ({children}) => {
  const {vehicle: configVehicle, useCache, dealership: configDealership, pageType} = useConfig()
  const navDispatch = useNavDispatchContext()
  const customerDispatch = useCustomerDispatchContext()
  const {registrationDispatch} = useRegistrationContext() || {}
  const dealState = useDealStateContext()
  const dealDispatch = useDealDispatchContext()
  const {customerData} = useCustomerStateContext()
  const [vehicleData, setVehicleData] = useState(null)
  const [creditAppConfig, setCreditAppConfig] = useState(null)
  const [isShareQuoteEnabled, setIsShareQuoteEnabled] = useState(null)
  const [isSaveDealEnabled, setIsSaveDealEnabled] = useState(null)
  const [isDocUploadsEnabled, setIsDocUploadsEnabled] = useState(null)
  const [drMinAmountFinanced, setDrMinAmountFinanced] = useState(null)
  const [vehicleLoaded, setVehicleLoaded] = useState(false)
  const [a2zApi, isApiReady, messages, setMessages] = useAxios()
  const [a2zCustomerApi, isCustomerApiReady] = useAxios('customer-token')
  const {tokenState, setTokenState} = useToken()
  const history = useHistory()
  const [isPriceLocked, setIsPriceLocked] = useState(null)
  const [dealership, setDealership] = useState()
  const {trackEvent, events} = useAnalytics()
  const [lead, setLead] = useState(null)
  const [shouldPreventRedirects, setShouldPreventRedirects] = useState(false)
  const [dealershipVehicleMissing, setDealershipVehicleMissing] = useState(true)
  const [isIframeVisible, setIsIframeVisible] = useState(false)

  const {
    data: {dealershipData, requestTypes},
    isError,
    isLoading,
  } = useCommonDependencies()

  useSessionId()

  useEffect(() => {
    if (dealership && vehicleLoaded && creditAppConfig && navDispatch) {
      navDispatch({type: 'setLoading', payload: LOADING_STATE.LOADED})
    }
  }, [dealership, vehicleLoaded, creditAppConfig, navDispatch])

  useEffect(() => {
    if (!vehicleData || !configDealership) {
      return
    }

    setIsPriceLocked(vehicleData.type.toLowerCase() === 'new' && configDealership.isPriceLocked)
  }, [configDealership?.isPriceLocked, vehicleData])

  useEffect(() => {
    if (isPriceLocked === null || !pageType || !isIframeVisible) {
      return
    }

    const params = {
      location: pageType,
    }

    if (isPriceLocked) {
      trackEvent(
        events.ENGAGEMENT,
        events.ENGAGEMENT.actions.CLICK,
        ACTION_DETAILS.PRICE.UNLOCK,
        null,
        params
      )
    } else {
      trackEvent(
        events.ENGAGEMENT,
        events.ENGAGEMENT.actions.CLICK,
        ACTION_DETAILS.PRICE.VIEW,
        null,
        params
      )
    }

    trackEvent(
      events.NAVIGATION,
      events.NAVIGATION.actions.NAVIGATE,
      ACTION_DETAILS.PAGES.PAYMENT,
      null,
      params
    )
  }, [isPriceLocked, pageType, isIframeVisible])

  useEffect(() => {
    // eslint-disable-next-line padding-line-between-statements
    ;(async () => {
      if (tokenState !== TOKEN_STATE.AUTHENTICATED && lead) {
        setLead(null)
      }

      if (customerData?.id || tokenState !== TOKEN_STATE.AUTHENTICATED || !isCustomerApiReady) {
        return
      }

      const {lead: leadInfo} = await whoami(
        a2zApi,
        a2zCustomerApi,
        customerData,
        customerDispatch,
        registrationDispatch,
        history
      )
      setLead(leadInfo)
    })()
  }, [configDealership?.id, isApiReady, tokenState, isCustomerApiReady, customerData])

  useEffect(() => {
    if (
      !vehicleData ||
      !configDealership?.id ||
      !vehicleData?.id ||
      !isApiReady ||
      isPriceLocked === null
    ) {
      return
    }

    // eslint-disable-next-line padding-line-between-statements
    ;(async () => {
      if (tokenState === TOKEN_STATE.UNAUTHENTICATED && !isPriceLocked) {
        await retrieveDealData(
          {vin: vehicleData.vin, dealershipId: configDealership.id, isPriceLocked, useCache},
          dealDispatch,
          a2zApi,
          {...getInitialDealState()}
        )

        return
      }

      if (!lead?.id) {
        return
      }

      await getLeadTradeAndDealData(
        lead.id,
        vehicleData,
        configDealership.id,
        {customerData},
        customerDispatch,
        dealDispatch,
        dealState,
        a2zApi,
        a2zCustomerApi,
        isPriceLocked,
        useCache,
        isSaveDealEnabled
      )
    })()
  }, [
    vehicleData,
    lead?.id,
    configDealership?.id,
    tokenState,
    isApiReady,
    isCustomerApiReady,
    isPriceLocked,
    useCache,
  ])

  useEventListener('message', (event) => {
    const config = event.data?.a2zDigitalRetailConfig
    const isSRP = config?.pageType === 'srp'
    const isVDP = event.data === WINDOW_IFRAME_OPEN_MESSAGE
    const isVDPAndAutoOpen = config?.pageType === 'vdp' && config?.vdpAutoOpen

    if (isSRP || isVDP || isVDPAndAutoOpen) {
      setIsIframeVisible(true)
    }
  })

  useEffect(() => {
    if (vehicleLoaded || !configVehicle?.vin || !configDealership?.id || !isApiReady || isLoading) {
      return
    }

    a2zApi
      .get(`/dealerships/${configDealership.id}/vehicles/${configVehicle.vin}`)
      .then((response) => {
        if (response.data?.data?.attributes) {
          const vehicle = response.data.data
          setVehicleData({id: vehicle.id, ...vehicle.attributes})
          setDealershipVehicleMissing(false)
        } else {
          setVehicleData(configVehicle)
        }

        setVehicleLoaded(true)
      })
      .catch(() => {
        const errorMessage = trans('error_unknown_fetching_vehicle')
        navDispatch({type: 'setLoading', payload: LOADING_STATE.FAILED})
        history.push(`/error?message=${errorMessage}`)
      })
  }, [configVehicle?.vin, configDealership?.id, isApiReady, isLoading])

  useEffect(() => {
    if (!vehicleLoaded || vehicleData?.id || isLoading) {
      return
    }

    let redirect = null

    if (tokenState === TOKEN_STATE.AUTHENTICATED) {
      const errorMessage = trans('vehicle_not_in_inventory')
      redirect = `/error?message=${errorMessage}`
    } else if (!customerData?.id) {
      redirect = '/register'
    }

    if (redirect) {
      setShouldPreventRedirects(true)
      history.push(redirect)
    }
  }, [customerData?.id, vehicleLoaded, vehicleData?.id, tokenState, isLoading])

  useEffect(() => {
    if (!dealershipData) {
      return
    }

    setDealership(dealershipData.data)
    setCreditAppConfig(
      dealershipData.included.find(({type}) => type === 'credit_app_configuration')?.attributes
    )
    initalizeShiftAnalytics(
      dealershipData.included.find(({type}) => type === 'analytics_configuration')?.attributes
    )
    setIsShareQuoteEnabled(
      !!dealershipData.included.find(({type}) => type === 'dr_share_quote')?.attributes?.isEnabled
    )
    setIsSaveDealEnabled(
      !!dealershipData.included.find(({type}) => type === 'dr_save_deal')?.attributes?.isEnabled
    )
    setIsDocUploadsEnabled(
      !!dealershipData.included.find(({type}) => type === 'dr_doc_uploads')?.attributes?.isEnabled
    )
    setDrMinAmountFinanced(
      dealershipData.included.find(({type}) => type === 'dr_min_amount_financed')?.attributes?.value
    )
  }, [configDealership?.id, dealershipData, isApiReady])

  if (isError) {
    return <Error />
  }

  return (
    <>
      {isError ? (
        <Error />
      ) : (
        <FrameContext.Provider
          value={{
            vin: configVehicle?.vin,
            vehicleData,
            isPriceLocked,
            dealership,
            creditAppConfig,
            vehicleLoaded,
            requestTypes,
            a2zApi,
            a2zCustomerApi,
            messages,
            setMessages,
            useCache,
            tokenState,
            setTokenState,
            isApiReady,
            isCustomerApiReady,
            isLangReady: !isLoading,
            shouldPreventRedirects,
            dealershipVehicleMissing,
            isIframeVisible,
            isShareQuoteEnabled,
            isSaveDealEnabled,
            isDocUploadsEnabled,
            drMinAmountFinanced,
          }}
        >
          {children}
        </FrameContext.Provider>
      )}
    </>
  )
}

FrameContextProvider.propTypes = {
  children: PropTypes.element,
}
